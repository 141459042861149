import { PartGroup } from './../interface/part-group';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { GROUP_KIND } from '../shared/lists/group-kind';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  private _isFromMatrix = true;


  groups: PartGroup[] = [];

  constructor(
    private http: HttpClient,
    private dataService: DataService,
  ) { }

  public async getAll(options?: { parts?: boolean, labors?: boolean, expenseType?: boolean }) {
    const url = `${environment.mkgoURL}/api/v1/group/`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp = await this.http.get<{groups: PartGroup[]} | PartGroup[]>(url, header).pipe(first()).toPromise();
    
    const groups: PartGroup[] = resp["groups"] || resp;
    let filteredGroups: PartGroup[] = [];

    if(options){
      if (options.parts) {
        filteredGroups = filteredGroups.concat(groups.filter(group => group.groupKind === GROUP_KIND.part));
      }
      if (options.labors) {
        filteredGroups = filteredGroups.concat(groups.filter(group => group.groupKind === GROUP_KIND.labor));
      }
      if (options.expenseType) {
        filteredGroups = filteredGroups.concat(groups.filter(group => group.groupKind === GROUP_KIND.expense));
      } 
    } else {
      filteredGroups = groups.reverse();
    }
    return filteredGroups;
  }

  public async getById(id: string) {
    const url = `${environment.mkgoURL}/api/v1/group/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.get(url, header).pipe(first()).toPromise();
    return resp as PartGroup;
  }

  public async getByGroupKind(kind: GROUP_KIND): Promise<PartGroup[]> {
    const url = `${environment.mkgoURL}/api/v1/group/filter?groupKind=${kind}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.get(url, header).pipe(first()).toPromise();
    return resp["groups"]
  }

  public async register(group: PartGroup): Promise<string> {
    const apiGroup = JSON.stringify(this.complyApi(group));
    const url = `${environment.mkgoURL}/api/v1/group`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.post(url, apiGroup, header).pipe(first()).toPromise();
    return resp['id'];
  }

  public async update(newGroup: PartGroup) {
    if (!newGroup._id) {
      throw "Grupo sem id"
    }
    const url = `${environment.mkgoURL}/api/v1/group/${newGroup._id}`;
    const apiGroup = JSON.stringify(this.complyApi(newGroup))
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http.put(url, apiGroup, header).pipe(first()).toPromise();
    return resp;
  }

  // public async delete(id: string) {
  //   const url = `${environment.mkgoURL}/api/v1/group/${id}`;
  //   const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
  //   const resp: any = await this.http.delete(url, header).pipe(first()).toPromise();
  //   return resp;
  // }

  private complyApi(group: PartGroup): any {
    let apiGroup: PartGroup = {
      "type": group.type,
      "description": group.description,
      "salePricePercentage": group.salePricePercentage,
      "groupKind": group.groupKind,
      "commissionDefault": group.commissionDefault,
      "commission": (group.commission || []).map(c => {
        return {
          person: c.user.id,
          value: c.value
        }
      }
      ),
      "discount": (group.discount || []).map(d => {
        return {
          person: d.user.id,
          value: d.value
        }
      }
      )
    }
    if (apiGroup.type === GROUP_KIND.part) {
      delete apiGroup.type
    }
    return apiGroup;
  }

}
